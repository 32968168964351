import React from 'react'
import { graphql, Link } from 'gatsby'
import { makeStyles, Grid, Typography } from '@material-ui/core'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import 'react-image-lightbox/style.css'
import Master from '../components/Master'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Button from '../ui/ButtonWhatsApp'
import Breadcrumbs from '../ui/CustomBreadcrumbs'

const useStyles = makeStyles({
  root: {
    maxWidth: '100%',
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  icon: {
    paddingRight: '5px'
  },
  image: {
    width: '100%',
    borderRadius: '5px',
    cursor: 'pointer'
  },
  mainImage: {
    width: '100%',
    borderRadius: '5px',
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  breadcrumbs: {
    color: 'rgba(0, 0, 0, 0.54)',
    textDecoration: 'none',
    '& a': {
      color: 'rgba(0, 0, 0, 0.54)',
      textDecoration: 'none',
    }
  },
  breadcrumb: {
    height: '30px'
  }
});

export default ({ data }) => {

  const classes = useStyles()

  const service = data.service

  return (
    <Master title={service.title}>
      <GatsbySeo
        title={service.title}
        description={service.description}
        canonical={`${process.env.SITE_URL}/servicos/${service.slug}`}
        noindex={false}
        nofollow={false}
        openGraph={{
          url: `${process.env.SITE_URL}/servicos/${service.slug}`,
          title: service.title,
          description: service.description,
          images: [
            {
              url: service.image,
              width: 700,
              height: 700,
              alt: service.title
            }
          ],
          site_name: `${process.env.SITE_NAME}`,
        }}
        twitter={{
          handle: '@handle',
          site: '@site',
          cardType: 'summary_large_image',
        }}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h1 className={classes.center}>{service.title}</h1>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Breadcrumbs>
            <Link color="inherit" to='/' className={classes.breadcrumbs}>
              Início
            </Link>
            <Link color="inherit" to={`/servicos`} className={classes.breadcrumbs}>
              Serviços
            </Link>
            <Typography color="textPrimary">{service.title}</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <img src={service.imageThumbnail} title={service.title} alt={service.title} className={classes.mainImage} />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <div dangerouslySetInnerHTML={{ __html: service.details }} />
          <p>
            <Button param={service.slug} attribute="servicos"><WhatsAppIcon className={classes.icon} /> Quero saber mais</Button>
          </p>
          <div className={classes.breadcrumb}></div>
        </Grid>
      </Grid>
    </Master>
  )
}

export const query = graphql`
  query($slug: String!) {
    service(slug: {eq: $slug}) {
        title
        slug
        description        
        keywords
        details
        image
        imageThumbnail
      }
  }
`